.rbc-calendar {
  background-color: #1F2937;
  color: #F3F4F6;
}

.rbc-toolbar {
  background-color: #374151;
  color: #F3F4F6;
  padding: 10px;
}

.rbc-toolbar button {
  color: #F3F4F6;
  background-color: #4B5563;
  border-color: #6B7280;
}

.rbc-toolbar button:hover,
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-color: #6B7280;
  border-color: #9CA3AF;
}

.rbc-header {
  background-color: #374151;
  color: #F3F4F6;
  padding: 10px;
  font-weight: bold;
  border-bottom: 1px solid #4B5563;
}

.rbc-header + .rbc-header {
  border-left: 1px solid #4B5563;
}

.rbc-row-content {
  border-bottom: none;
}

.rbc-month-view {
  border: 1px solid #4B5563;
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #4B5563;
}

.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #4B5563;
}

.rbc-off-range-bg {
  background-color: #4B5563;
}

.rbc-today {
  background-color: #374151;
}

.rbc-event {
  background-color: #14B8A6;
  color: #F3F4F6;
}

.rbc-event.rbc-selected {
  background-color: #0D9488;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0;
}

.rbc-time-view,
.rbc-time-header-content,
.rbc-time-header.rbc-overflowing,
.rbc-time-header-gutter,
.rbc-time-gutter,
.rbc-time-content {
  border-color: #4B5563;
}

.rbc-timeslot-group {
  border-bottom: 1px solid #4B5563;
}

.rbc-time-gutter,
.rbc-time-content {
  color: #F3F4F6;
}

.rbc-current-time-indicator {
  background-color: #14B8A6;
}

.rbc-agenda-view table.rbc-agenda-table {
  border: 1px solid #4B5563;
  color: #F3F4F6;
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  background-color: #374151;
  border-bottom: 1px solid #4B5563;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  border-top: 1px solid #4B5563;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid #4B5563;
}

.rbc-agenda-time-cell {
  padding-left: 10px;
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell,
.rbc-agenda-event-cell {
  padding: 10px;
}